<script>
import { SuawHeading } from "@/components";
import logoWriteBackground from "../../../../public/img/home/logo-write-background.svg";

export default {
  name: "DynamicTitle",
  components: { SuawHeading },
  props: {
    before: {
      type: Array,
      default: () => []
    },
    after: {
      type: Array,
      default: () => []
    },
    size: {
      type: String,
      default: "large",
      validator: value => ["large", "medium"].includes(value)
    },
    weight: {
      type: String,
      default: "regular",
      validator: value => ["bold", "regular"].includes(value)
    },
  },
  data() {
    return {
      logoWriteBackground,
    };
  },
  computed: {
    computedLevel() {
      return this.size === "large" ? 1 : 2;
    },
    computedWeight() {
      return this.weight === "regular" ? 500 : 700;
    },
    writeStyles() {
      return {
        fontSize: this.size === "large" ? "48px" : "36px",
      };
    },
    logoStyles() {
      return {
        width: this.size === "large" ? "170px" : "130px",
      };
    },
  },
};
</script>

<template>
  <div class="dynamic-title">
    <template v-if="before.length">
      <SuawHeading
        v-for="(content, index) in before"
        :key="'before-' + index"
        :level="computedLevel"
        alignment="center"
        :content="content"
        :style="{ fontWeight: computedWeight }"
        class="dynamic-title__heading"
      />
    </template>

    <!-- Hardcoded "Shut Up & Write!" Section -->
    <div class="dynamic-title__core">
      <SuawHeading class="dynamic-title__heading" :level="computedLevel" alignment="center" content="Shut" :style="{ fontWeight: computedWeight }" />
      <SuawHeading class="dynamic-title__heading" :level="computedLevel" alignment="center" content="Up" :style="{ fontWeight: computedWeight }" />
      <SuawHeading class="dynamic-title__heading" :level="computedLevel" alignment="center" content="&amp;" :style="{ fontWeight: computedWeight }" />
      <div class="dynamic-title__write-wrapper">
        <b class="dynamic-title__write" :style="writeStyles">Write!</b>
        <img class="dynamic-title__logo-background" :src="logoWriteBackground" alt="Logo Write Background" :style="logoStyles" />
      </div>
    </div>

    <template v-if="after.length">
      <SuawHeading
        v-for="(content, index) in after"
        :key="'after-' + index"
        :level="computedLevel"
        alignment="center"
        :content="content"
        :style="{ fontWeight: computedWeight }"
        class="dynamic-title__heading"
      />
    </template>
  </div>
</template>

<style scoped lang="scss">
.dynamic-title {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;

  &__core {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
  }

  &__write-wrapper {
    position: relative;
  }

  &__heading {
    z-index: 1;
    color: var(--sem-color-text-dark);
    line-height: 125%;
    margin: 0 3px;
    font-family: var(--secondary-font-family);
  }

  &__write {
    margin: 0 3px;
    font-weight: 700;
    line-height: 125%;
    font-family: var(--secondary-font-family);
    color: var(--sem-color-text-dark);
    z-index: 1;
  }

  &__logo-background {
    position: absolute;
    height: auto;
    z-index: 0;
    left: -12%;
    top: 25%;

    @media (max-width: 400px) {
      left: -11%;
    }
  }
}
</style>
